import FundUnicefTemplate from '@/page-blocks/about-us/clear/fund/unicef/FundUnicefTemplate';
import ReportUnicef2023 from '@/page-blocks/about-us/clear/fund/unicef/report.unicef_2023';
import { PageProps } from 'gatsby';
import React from 'react';

const FundUnicef2023: React.FC<PageProps> = (props) => (
  <FundUnicefTemplate year={2023} {...props}>
    <ReportUnicef2023 />
  </FundUnicefTemplate>
);

export default FundUnicef2023;